<template>
  <div class="content">
    <div>
      <div class="top-message">
        メッセージボックス
      </div>
      <div class="box-message">
        <div class="msg-wrap">
          <div class="hdg-02">
            <span>お知らせ</span>
          </div>
        </div>
        <div class="msg-inr">
          <ul>
            <li>
              <span class="msg-txt">
                <a>特にありません</a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.content {
  width: 950px;
  margin: 0 auto;
  padding: 0;
  text-align: left;
}

.top-message {
  font-size: 23px;
  font-weight: bold;
  margin-top: 50px;
}

.box-message {
  width: 950px;
  margin-top: 10px;
  border: solid 1px #dadad9;
  box-shadow: 0px 2px 2px #e1e1e1;
  box-sizing: border-box;
  position: relative;
}

.box-message .msg-wrap {
  text-align: right;
  margin-top: 15px;
  border-bottom: 1.5px #dadad9 solid;
}

.box-message .hdg-02 span {
  color: #686868;
  text-decoration: none;
  white-space: nowrap;
  font-size: 17px;
  border-bottom: 2.5px #272727 solid;
  margin-right: 40px;
  padding: 0 2px;
}

.box-message .msg-inr {
  margin: 30px 25px;
}

.box-message .msg-inr a {
  color: #000;
  text-decoration: none;
}

.box-message .msg-inr a:hover {
  opacity: 0.5;
}
</style>
